/* TrainerCoursesNavbar */
.listStyles {
	list-style-type: none;
	padding: 7px 15px;
	border: 1px solid #efefef;
	cursor: pointer;
	background-color: #ffffff;
	/* border: none; */
}

.active-tab {
	border-bottom: 2px solid #4b89dc;
	color: #4b89dc;
}
