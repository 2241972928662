/* Batch Table Css Styles */
/* .d-flex .dropdown-toggle::after {
  margin-left: 2em !important;
}

.pb-5 .d-flex .dropdown-menu.show {
  width: 12.4rem;
} */

.nav-link {
	color: black !important; /* Set tab text color to black */
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: rgb(34, 122, 238) !important;
	border-bottom: 3px solid #fd7e14 !important;
}

.activeStyle {
	color: #fff;
	background-color: #f47951;
	border-radius: 5px;
	border: none;
	padding-top: 0px;
	padding-bottom: 0px;
	font-size: 15px;
	margin: 1px;
}

/* BatchAssessmentProgress */
.apexcharts-text {
	font-size: 0.8rem;
}

/* Batch Description */
.batch-description_keys {
	list-style-type: none;
	color: #f47951;
	font-weight: 500;
	font-size: 16px;
}

.batch-description_values {
	color: #1b1329;
	list-style-type: none;
	font-size: 16px;
}

.course {
	cursor: pointer;
	text-decoration: underline;
	color: blue;
}
.batch-contributor {
	color: blue;
	text-decoration: underline;
	cursor: pointer;
}

/* Batch Details */
.batch-details__keys {
	color: #1b1329;
	font-weight: 500;
	font-size: 17px;
}

.batch-details__value {
	background-color: #4a4a4a1a;
}

.progressbar {
	width: 70%;
}

/* Batch Duration */
.batch-duration_heading {
	gap: 15rem;
}

.date {
	margin-top: calc(-1 * 0.5rem);
}

.batch-duration__label {
	font-weight: 600;
	font-size: 14px;
}

.batch-duration__bar {
	background-color: #4a4a4a07;
	height: 5rem;
	border-radius: 1rem;
}

.batch-duration__slide1,
.batch-duration__slide2 {
	height: 0.52rem;
	border-radius: 20px;
}

.batch-duration__slide1 {
	background-color: #77f2a4;
}

.batch-duration__slide2 {
	background-color: #f5b9bc;
}

.batch-duration__tab1,
.batch-duration__tab2 {
	border-radius: 6px 6px 16px 16px;
	border: 10px;
	width: 6.3rem;
}

.batch-duration__tab1 {
	background-color: #e9f4ed;
	color: #438d5e;
}

.batch-duration__tab2 {
	background-color: #f8e8e9;
	color: #a06568;
}

.batch-duration__month_year {
	font-size: 12px;
}

.batch-duration__tabs {
	margin-top: calc(-1 * 0.7rem);
}

/* Batch Form */
/* .form-control:focus {
  color: #0d0d0e;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
} */

/* #dropdown-basic {
  border: 1px solid #cacaca;
  width: 480px;
}

.css-i4bv87-MuiSvgIcon-root {
  margin-top: -64px;
  margin-left: 585px;
} */

.search-control {
	width: 380px;
	height: 3.8rem;
}

.search-error {
	color: red;
	margin-top: 0px;
}

.dates-control {
	padding-top: 0.5rem !important;
	height: auto !important;
	padding-bottom: 0.625rem;
}

.search-inner {
	display: flex;
}

.search-container {
	width: 280px;
	display: flex;
	flex-direction: column;
}

/* .col-6 .dropdown {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #0000002d;
  border-radius: 5px;
  padding-left: 10px;
} */

/* .dropdown:empty {
  border: none;
}

.dropdown-row {
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
} */

.searchIcon {
	margin-top: 10px;
	margin-left: -30px;
	position: relative;
	display: block;
	font-size: 20px;
	color: gray;
}
.searchIcon:hover {
	cursor: pointer;
}
.deleteIcon {
	margin-top: 12px;
	margin-left: -28px;
	position: relative;
	display: none;
}
.deleteIcon:hover {
	cursor: pointer;
}
.react-datepicker {
	margin-bottom: 1rem;
}
.read-only-field {
	background-color: #e9ecef !important;
}