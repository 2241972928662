.sidebar {
	width: 16rem;
	height: 100%;
	background: #1b1a47;
	/* box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important; */
	/* box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1); */
}

.sidebar-styles {
	font-weight: 500;
	font-size: 18px;
	text-decoration: none;
	background: #1b1a47;
	color: #130f1a;
	align-items: flex-start;
}

.sidebarStyle a {
	border-left: 3px solid transparent !important;
	cursor: pointer;
	height: 56px;
	display: flex;
	color: #b5bfc7 !important;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	text-align: center;
	/* color: #fff; */
	font-size: 18px;
	padding: 10px 20px;
}

.sidebarStyle.active {
	border-left: 3px solid #4b89dc;
	background-color: #303056;
}

.sidebarStyle a.active {
	color: #fff !important;
	background-color: #303056;
	border-left: 3px solid #4b89dc !important;
}

.sidebar-styles:hover {
	color: #fff !important;
}

.sidebar-items {
	margin-left: 5%;
}

.sidebarTitles {
	display: block;
	font-size: 15px;
	/* overflow: hidden; */
	width: 70px;
	line-height: 12px;
	text-align: flex-start;
	/* margin-top: 2px; */
	padding-left: 4px;
	padding-right: 4px;
	max-height: 24px;
}

/* .semi-circle {
	width: 44px;
	height: 44px;
	position: absolute;
	left: calc(-1 * 1.7rem);
	border-radius: 22px;
	text-align: center;
} */

/* a.sidebar-styles.active span {
	background: #f47951;
} */

a.sidebar-styles:hover {
	color: #fff;

	/* background-color: #f47a511f; */
}
