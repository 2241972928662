.login-page {
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100vh;
	max-width: 1920px;
	max-height: 1080px;
	background: #ffffff 0% 0% no-repeat padding-box;
	opacity: 1;
}
.background-container {
	background: transparent linear-gradient(128deg, #1b1a47, #1b1a47) 0% 0%
	no-repeat padding-box;
	width: 50%;
	height: 100vh;
	bottom: 0%;
	right: 4%;
	position: relative;
}
.image-container {
	position: absolute;
	top: 0;
	right: 17%;
	height: 100vh;
	width: 1500px;
	opacity: 1;
}

.login-container {
	position: absolute;
	top: 25%;
	right: 8%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.fission-labs-logo-container {
	float: right;
	padding-top: 25px;
}
.fission-labs-logo {
	width: 70%;
}

.background-components {
	position: absolute;
	right: 53%;
	width: 47%;
	bottom: 0%;
}
.login-image {
	position: absolute;
	left: 13%;
	width: 27%;
	top: 14%;
}
.description {
	position: absolute;
	bottom: 10%;
	color: rgb(235, 235, 235);
	text-align: center;
	font-size: 13px;
	left: 12%;
}
.app-heading {
	left: 80%;
	text-align: left;
	font: normal normal bold 35px Urbanist;
	letter-spacing: 0px;
	color: #130f1a;
	opacity: 1;
}
.welcome-heading {
	color: #1b1a47;
	font: normal normal 600 25px Urbanist;
	padding-top: 12px;
	padding-bottom: 5px;
}
.login-button {
	border: none;
	background-color: transparent;
	font-size: 20px;
	font-weight: 600;
	width: 337px;
	height: 77px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 12px 3px 12px #00000029;
	border: 1px solid #ededed;
	opacity: 0.4;
	display: flex;
	align-items: center;
	justify-content: center;
}
.login-text {
	text-align: left;
	font: normal normal medium 29px/35px Urbanist;
	letter-spacing: 0px;
	color: #130f1a;
	opacity: 1;
}
.google-logo {
	opacity: 1;
	height: 22px;
	width: 22px;
	font-size: 22px;
	padding-right: 8px;
	padding-top: 3px;
}

.pointer-disabled {
	cursor: not-allowed !important;
}
.pointer-enabled {
	cursor: pointer;
}

.label {
	margin-bottom: 0px;
	font-size: larger;
	font-weight: 500;
}
.loginbutton {
    border: white !important;
    background-color: #1b1a47 !important;
}
.dotted-line {
	width: 100%;
	display: flex;
	flex-direction: row;
	text-transform: uppercase;
	border: none;
	font-size: 12px;
	font-weight: 500;
	margin: 0;
	padding: 10px;
}

.dotted-line:after,
.dotted-line:before {
	content: '';
	border-bottom: 1px solid #c2c8d0;
	flex: 1 0 auto;
	height: 0.5em;
	margin: 0;
}

.googleloginbutton {
	color: #4a4a4a !important;
	border: 1px solid #ffffff !important;
	background-color: #ffffff !important;
	box-shadow: -3px 2px 12px -1px #c0bec5;
}
.googleButton {
	width: 20px;
}
