/* .App {
  text-align: center;
} */

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* dropdown css */
.dropdown-menu.show {
	height: auto;
	overflow-x: hidden;
	width: auto;
	max-height: 250px;
}

/* ag-grid */
.ag-theme-alpine .ag-header-cell-resize::after {
	content: '';
	position: absolute;
	z-index: 1;
	display: block;
	left: calc(50% - 1px);
	width: 1px !important;
	height: 100% !important;
	top: auto !important;
	background-color: rgba(186, 191, 199, 0.5);
	background-color: var(
		--ag-header-column-resize-handle-color,
		rgba(186, 191, 199, 0.5)
	);
}
.ag-header-cell-text {
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 28px;
}
.ag-theme-alpine .ag-header {
	background: #f9fafb;
	border-bottom: solid 0px !important;
}
.ag-theme-alpine .ag-paging-panel {
	font-size: 16px;
	color: darkslategray !important;
}
.ag-body-viewport {
	display: flex;
	border-top: solid 6px #efefef;
}
.ag-theme-alpine .ag-root-wrapper {
	border: 0px solid #d0d5dd !important;
	box-shadow: 0px -2px 16px -4px rgba(16, 24, 40, 0.12);
	border-radius: 8px;
}
.ag-cell-value,
.ag-group-value {
	font-style: normal;
	font-size: 15px;
	color: #000;
	text-align: center;
}
.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
	padding-left: 8px !important;
	padding-right: 8px !important;
}
.ag-theme-alpine .ag-cell {
	padding-left: 12px !important;
	padding-right: 8px !important;
}
.ag-header-group-cell {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.ag-header-cell-label {
	color: #000;
	justify-content: center;
}

.ag-header-cell-label:hover {
	color: #4b89dc;
}

.ag-header-cell-label:active {
	color: #4b89dc !important;
}

.ag-theme-alpine .ag-row-odd {
	/* background-color: #eff2f7 !important; */
}

.ag-theme-alpine .ag-row {
	border-width: 1px !important;
}

.ag-header-row.ag-header-row-column {
	background-color: #ffffff;
}

.iconStyle {
	font-size: larger;
	color: #000000;
}

.example-header {
	position: absolute;
	bottom: 5px;
}

.rc-pagination-item.rc-pagination-item-active a,
.rc-pagination-item.rc-pagination-item-active a:hover,
.rc-pagination-prev.rc-pagination-item-active a,
.rc-pagination-prev.rc-pagination-item-active a:hover,
.rc-pagination-next.rc-pagination-item-active a,
.rc-pagination-next.rc-pagination-item-active a:hover,
.rc-pagination-total-text.rc-pagination-item-active a,
.rc-pagination-total-text.rc-pagination-item-active a:hover {
	background-color: #458ad6 !important;
	border-color: white !important;
}

ul.rc-pagination.pagination-data {
	margin: 0px;
}

button.dropdown-toggle.btn.btn-primary {
	background-color: #ffffff;
	color: #000000;
	border-color: #e7e0e0e3;
}

/* .search-bar {
  margin-right: calc(1 * -2rem);
} */

.error {
	color: red;
}

/* THE BELOW CSS STYLING IS FOR TYPEAHEAD DROPDOWN CSS */
.rbt-input-multi.form-control {
	/* height: 120px !important; */
	margin: auto;
	overflow-x: hidden;
	overflow-y: auto;
	scroll-snap-type: y mandatory;
}
.rbt-token-label {
	color: #fff;
	background-color: #88b1da !important;
	border: #fff;
	padding: 3px;
	border-radius: 0.1rem;
}
.rbt-input-multi.form-control {
	height: auto;
	padding: 0px;
}
button.close.btn-close.rbt-close.rbt-token-remove-button {
	color: #fff;
	background-color: #88b1da !important;
	z-index: auto;
}

/* Add button */
button.btn.btn-success.px-4 {
	background-color: #f47951;
	border-color: #f47951;
}
.search-bar input[type='text'] {
	line-height: 1.6;
}

.left-section {
	width: 250px !important;
}

.body-container {
	background: rgb(247, 247, 247);
	height: 92vh;
	overflow-y: auto;
	width: calc(100% - 250px) !important;
}
/* Date picker CSS */
.my-header-class .ag-header-cell-label {
	justify-content: center !important;
}
.session-date {
	position: relative;
	width: 100%;
}

.session-date::-webkit-calendar-picker-indicator {
	background-position: right;
	background-size: auto;
	cursor: pointer;
	position: absolute;
	bottom: 2;
	left: 0;
	right: 3px;
	top: 2;

	width: auto;
}
.plusIconStyle {
	font-size: 36px;
	color: darkgreen;
	cursor: pointer;
	margin-left: -16px;
}
.minusIconStyle {
	font-size: 36px;
	color: darkred;
	cursor: pointer;
}

/* upload file CSS */
.delete-file_icon {
	margin-top: 10px;
	margin-left: calc(-1 * 30px);
	position: relative;
	display: block;
	color: gray;
	cursor: pointer;
}
.delete-file_icon:hover {
	color: #d2042d;
}
input[type='file'] {
	color: rgba(0, 0, 0, 0);
}
input[type='text'] {
	z-index: 0 !important;
}
.uploaded-file {
	display: flex;
	align-items: center;
	background-color: #cf2f2f29;
	width: 50%;
	margin-left: -12rem;
	padding-top: 2px;
}
.accepted-files {
	color: #acacac;
}

/* RC Pagination */
.rc-pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
	margin-right: 8px;
}

.rc-pagination-total-text {
	margin-right: 12px;
	cursor: initial;
}

.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-total-text {
	display: block !important;
}

.rc-pagination-jump-next,
.rc-pagination-jump-prev,
.rc-pagination-next,
.rc-pagination-prev {
	display: inline-block;
	min-width: 28px;
	height: 28px;
	color: rgba(0, 0, 0, 0.85);
	line-height: 28px;
	text-align: center;
	vertical-align: middle;
	list-style: none;
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.3s;
}
.rc-pagination-jump-next button,
.rc-pagination-jump-prev button {
	background: transparent;
	border: none;
	cursor: pointer;
	color: #666;
}
.rc-pagination-jump-next button:after,
.rc-pagination-jump-prev button:after {
	display: block;
	content: '•••';
}
.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-total-text {
	min-width: initial;
	height: auto;
	line-height: initial;
	background-color: transparent;
	border: none;
	cursor: pointer;
}
.rc-pagination-item a,
.rc-pagination-item button,
.rc-pagination-prev a,
.rc-pagination-prev button,
.rc-pagination-next a,
.rc-pagination-next button,
.rc-pagination-total-text a,
.rc-pagination-total-text button {
	padding: 6px 8px;
	height: auto;
	min-width: 32px;
	min-height: 32px;
	border-radius: 8px;
	border: 1px solid transparent;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: 500;
	color: #656f84 !important;
	transition: 0.3s;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-o-transition: 0.3s;
}
.rc-pagination-item.rc-pagination-item-active a,
.rc-pagination-item.rc-pagination-item-active a:hover,
.rc-pagination-prev.rc-pagination-item-active a,
.rc-pagination-prev.rc-pagination-item-active a:hover,
.rc-pagination-next.rc-pagination-item-active a,
.rc-pagination-next.rc-pagination-item-active a:hover,
.rc-pagination-total-text.rc-pagination-item-active a,
.rc-pagination-total-text.rc-pagination-item-active a:hover {
	background-color: #5a8dee;
	border-color: #5a8dee;
	color: #ffffff !important;
}
.rc-pagination-item a:hover,
.rc-pagination-item button:hover,
.rc-pagination-prev a:hover,
.rc-pagination-prev button:hover,
.rc-pagination-next a:hover,
.rc-pagination-next button:hover,
.rc-pagination-total-text a:hover,
.rc-pagination-total-text button:hover {
	background-color: #eceff5;
	border-color: #eceff5;
}

.dropdown-menu {
	left: 0rem !important;
}
/* ReactdatePicker */
.date-picker-container {
	position: relative;
}

.date-picker {
	padding-right: 30px; /* To accommodate the icon */
}

.calendar-icon {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	pointer-events: none; /* To prevent the icon from capturing click events */
}
