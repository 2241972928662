.year {
	display: inline;
	border-radius: 12px;
	padding: 0.5rem;
	text-align: center;
	font-size: 16px;
}
.apexcharts-toolbar,
.apexcharts-menu-icon {
	display: none;
}

.apx-legend-position-bottom {
	display: none !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	border-bottom: 3px solid #fd7e14;
}
.apexcharts-yaxis {
	border-right: 3px solid #fd7e14;
}
.apexcharts-xaxis-inversed {
	border-right: 3px solid #fd7e14;
}
.card {
	border-radius: 2rem;
	border-left: 3px solid #eeeeee;
}

.chart {
	border: none;
}

.apexcharts-legend {
	right: 8rem !important;
}

.apexcharts-svg {
	border: none;
	border-radius: 35px;
}

.viewMore {
}

/* .mt-3 .dropdown-menu.show {
  width: 0rem !important;
  height: 100px;
  overflow-y: scroll;
} */

.dashboardGraphHeader {
	width: 27% !important;
}
.icons:hover {
	color: #88b1da;
}
.pointerStyle {
	cursor: pointer;
}

.title {
	color: #120f1a;
}

hr {
	margin: 0 !important;
}

/* OLd */

.title {
	display: inline;
	border-radius: 12px;
	padding: 0.5rem;
	text-align: center;
}

.apx-legend-position-bottom {
	display: none !important;
}

.apexcharts-toolbar,
.apexcharts-menu-icon {
	display: none;
}

.card {
	border-radius: 2rem;
	border-left: 3px solid #eeeeee;
}

.chart {
	border: none;
}

.apexcharts-legend {
	right: 8rem !important;
}

.apexcharts-svg {
	border: none;
	border-radius: 35px;
}

.BatchAssessmentsCard {
	height: 48.5%;
	padding-top: 0.13rem;
}

.dropdown-menu {
	left: 0rem !important;
}

.dropdownStyle {
	width: 100px;
}
/* Top 5 */
.viewMoreBtn {
	position: absolute;
	font-size: medium;
	right: 8%;
	bottom: 0.5rem;
	color: #ffffff;
	background-color: #458bd6;
	border-color: #458bd6;
	/* border: none !important; */
}

.topFiveCard {
	height: 22rem !important;
	position: relative;
}
.viewMoreBtnIcon {
	font-size: larger;
}

.btn:focus-visible {
	color: var(--bs-btn-hover-color);
	background-color: rgb(240, 140, 105) !important;
	border: none !important;
	outline: 0 !important;
	box-shadow: none !important;
}

/* Top 5 batches Techwise  */
.topFiveBatchesTechwise {
	height: 21rem;
}

/* Info  */
.totalEmployeesBorder {
	border-bottom: 1px solid #7ab8f7 !important ;
	margin-top: 2rem;
}

.totalCourseCountBorder {
	border-bottom: 1px solid #77f2a4 !important ;
	margin-top: 1.5rem;
}

.durationBorder {
	border-bottom: 1px solid #f5aaae !important ;
	margin-top: 1.5rem;
}

.TotalBatchesBorder {
	border-bottom: 1px solid #fdd7a6 !important ;
	margin-top: 1.5rem;
}

.totalEmployeesCountValue {
	background-color: #d7eafd;
}

.totalCourseCountValue {
	background-color: #e9f4ed;
}

.durationValue {
	background-color: #f8e8e9;
}

.totalBatchesValue {
	background-color: #f8f1e8;
}
