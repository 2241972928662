/*Profile CSS */
.employee-profile-image {
	height: 145px;
	opacity: 1;
	border-radius: 100%;
	border: 2px solid #4b89dc;

	margin-left: 27px;
}
.employee-profile-title {
	font-size: 18px;
	font-weight: 600;
}
.employee-profile-values span {
	font-size: 16px;
	color: rgba(18, 15, 26, 0.5);
}
.size {
	width: 100%;
}
.status-description_key {
	color: #f47951;
	font-weight: 500;
}
.status-description_value {
	color: #120f1a;
}

/* Skills CSS */
/* .chip {
	font-size: 16px;
	border-radius: 25px;
	background-color: #f47951;
} */
.chip {
	font-size: 16px !important;
	border-radius: 6px !important;
	background-color: #88b1da !important;
}

/* Status CSS */
/* .form-check-input:checked {
  background-color: #f47951 !important;
  border-color: #f47951 !important;
} */
button.accordion-button.collapsed {
	padding: 10px !important;
}

button.accordion-button {
	padding: 10px !important;
}

.status {
	color: #f47951;
	font-weight: 500;
}

.status_value {
	font-size: 18px;
	margin-top: 1px;
}

/* .mt-3 .dropdown-menu.show {
  width: auto !important;
} */

.average {
	cursor: pointer;
	color: blue;
}

.gender-read-only {
	pointer-events: none;
}

.read-only-field {
	background-color: #e9ecef !important;
}

/* .chooseFile {
	width: 6.688rem !important;
} */

/* .uploadFile {
	width: calc(100% - 17.65rem) !important;
	border-radius: 0rem 0.313rem 0.313rem 0rem !important;
} */

.countryCode {
	width: 4.75rem !important;
}

.contactValue {
	width: calc(100% - 4.75rem) !important;
}
