/* Program Details */
.duration-card {
	padding: 0 5rem;
}

.duration__tab {
	background-color: #f8e8e9;
	color: #a06568;
	border-radius: 6px 6px 16px 16px;
	border: 10px;
	padding: 0 2rem;
}

.course-duration_text {
	font-size: 14px;
	font-weight: 500;
}

.downloadBtn {
	border: none;
	color: #ffffff;
	border-radius: 20px;
	background-color: #dde6ed;
	position: relative;
	padding: 3px 6px;
	cursor: pointer;
}

.downloadBtn:hover {
	color: #ffffff;
}

.columnColor {
	color: #4b89dc;
}

/* Program Table */
.add-button {
	border-color: #fff !important;
	background-color: #67c23a !important;
}

.add-button:hover {
	background-color: #4c9e45 !important;
}

.form-title {
	color: #f47951;
}

/* .form_submit-button {
	background-color: #f47951 !important;
	color: #ffffff !important;
}

.form_cancel-button {
	background-color: #b5b5b5 !important;
	color: #ffffff !important;
} */

.average {
	cursor: pointer;
	color: blue;
	text-decoration: underline;
}
.disabled-edit-icon {
	color: #dddddd;

	cursor: not-allowed;
}
.fileStyle {
	border: 2px solid #f06435;
	display: inline-block;
	border-radius: 13px;
	background-color: #f47951;
	color: rgb(255, 255, 255);
}

.fileInput {
	margin: 1.5rem 0rem 1rem !important;
}

.courseChooseFile {
	width: 6.5rem !important;
}

.courseUploadFile {
	border-radius: 0rem 0.313rem 0.313rem 0rem !important;
	width: calc(100% - 6.5rem) !important;
}

.courseMandatory {
	margin-top: 1.9rem !important;
}
