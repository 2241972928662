.button-container {
	white-space: nowrap;
}

.button-container button {
	display: flex;
	align-items: center;
}

.createFeedbackFormBtn {
	background-color: #f47951 !important;
	border: none !important;
}

.addIcon {
	margin-top: 0.1rem;
}

.disabledButton {
	cursor: not-allowed;
}
.formName {
	color: #f47951;
}
.cancelButton {
	background-color: #808080 !important;
}

.questionTable {
	border-bottom: 1px solid #e9e9e9 !important;
	width: 90%;
	margin: 0 4%;
}

.givenTorole {
	width: 5rem;
}
.selectRole {
	width: 15rem !important;
}
.table-header-cell {
	min-width: 100px;
	width: auto;
	text-align: center;
}
