body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
} */

.cursorOnHover {
	cursor: not-allowed;
}

/* Styles for React bootstrap form */
/* FormStyles.css */

.headerStyle {
	font-weight: 550;
	color: #120f1a;
	font-size: 16px;
	margin: 10px 0;
}

.descriptionMainHeader {
	font-size: 20px;
	min-width: 250px;
	max-width: 250px;
	margin-right: 20px;
	color: var(--fc_3);
	display: inline-flex;
	align-items: center;
}

.descriptionViewRowWrapper {
	clear: both;
	border-bottom: 1px solid #eff2f7;
	line-height: 20px;
	margin-top: 7px !important;
	/* margin-left: 15px; */
	/* margin-right: 15px; */
	position: relative;
}

.descriptionViewRowWrapperLabel {
	color: #666666;
	width: -webkit-calc(50% + 20px);
	width: -moz-calc(50% + 20px);
	width: calc(50% + 20px);
	float: left;
	padding-top: 0 !important;
	padding-right: 20px;
	padding-bottom: 4px;
	text-indent: 15px;
	word-break: break-word;
	white-space: nowrap;
	/* overflow: hidden; */
	text-overflow: ellipsis;
	margin: 0;
}

.titleInView {
	color: #666666 !important;
	font-size: 14px !important;
}

.titleInViewValues {
	color: #000 !important;
	font-weight: 550;
	font-size: 14px !important;
}
.descriptionViewRowWrapperLabelValue {
	color: #000000;
}

.subHeader {
	border: none;
	height: 50px;
	width: 100%;
	padding: 15px 20px;
	position: relative;
	overflow: hidden;
}

.subHeader::before {
	content: '';
	position: absolute;
	top: 50%;
	left: -20px;
	background-color: #4b89dc;
	width: 25px;
	height: 30px;
	border-radius: 100%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

.subHeader h3 {
	font-size: 16px;
	color: #040d12;
	font-weight: 500;
	margin: auto;
	/* display: inline-block; */
	vertical-align: sub;
}

.btnStyle {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	font-size: 16px;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	padding: 5px 12px;
	font-size: 14px;
	line-height: 1;
	border-radius: 3px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.positiveButton {
	background-color: #4b89dc;
	border-color: #4b89dc;
	color: #fff;
}

.positiveButton:hover {
	background-color: #5791dc;
	color: #fff;
	font-weight: 400;
}

.negativeButton {
	border-color: #c0c0c0;
	color: #000000;
	background-color: #ffffff;
}

.inputLabel {
	text-overflow: unset !important;
	overflow: unset !important;
	white-space: normal !important;
	line-height: 20px;
	word-break: break-word;
	color: #666;
}

.errorText {
	color: #e95b6d;
	font-size: 12px;
	width: 98%;
	line-height: 13px;
}

.chooseFile {
	width: 6.688rem !important;
}

.employeeUploadInputField {
	width: calc(100% - 18.65rem) !important;
	background-color: #f6f7f8 !important;
	border-radius: 0rem 0.313rem 0.313rem 0rem !important;
}

.programUploadInputField {
	width: calc(100% - 7.2rem) !important;
	background-color: #f6f7f8 !important;
	border-radius: 0rem 0.313rem 0.313rem 0rem !important;
}

.uploadInputField {
	width: calc(100% - 7.65rem) !important;
	background-color: #f6f7f8 !important;
	border-radius: 0rem 0.313rem 0.313rem 0rem !important;
}
/* Base styles for the input container */
.styled-input {
	border: none !important;
	border-bottom: 1px solid #ccc !important;
	width: 100%;
	padding: 5px !important;
	transition: border-color 0.3s ease-out !important;
	border-radius: 0px !important;
	box-shadow: 0 0 0 2px #fff !important;
	outline: none !important;
	line-height: 20px !important;
	font-size: 16px !important;
	color: #332244;
}

.rbt-input {
	border: none !important;
}

.rbt-input.focus {
	border-color: #fff !important;
	box-shadow: none !important;
}
/* Styles for hover and focus states */
.styled-input:hover,
.styled-input:focus {
	border-color: #4b89dc !important;
}

.styled-input.error {
	border-color: #e95b6d !important; /* Change border color for error state */
}

.delete-icon:hover {
	color: brown;
	cursor: pointer;
}

.dropdown-item.active,
.dropdown-item:active {
	/* color: var(--bs-dropdown-link-active-color);
    text-decoration: none; */
	background: #f5f7fd !important;
	color: #666 !important;
}
