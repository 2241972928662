/* Assessment Details  */
.view-header {
	display: inline;
	align-items: center;
	padding-right: 25px;
	margin-bottom: 0px;
	/* color: #f47951; */
}
.arrow-left {
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-right: 10px solid #f47951;
	padding-left: 10px;
	cursor: pointer;
}
.arrow-right {
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 10px solid #f47951;
	padding-right: 10px;
	cursor: pointer;
}
.disabled-arrow-right {
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 10px solid #fabca8;
	padding-right: 10px;
	cursor: not-allowed;
}
.disabled-arrow-left {
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-right: 10px solid #fabca8;
	padding-left: 10px;
	cursor: not-allowed;
}
.bar-graph-container {
	/* border-radius: 15px; */
	display: flex;
	align-items: center;
	/* background-color: #fff; */
	width: 100%;
	margin-bottom: 3rem;
}
.bar-graph {
	width: 100%;
	padding-right: 5px;
}
.average-display-container p {
	margin-right: 10px;
}
.deadline__tab {
	background-color: #f8e8e9;
	color: #a06568;
	border-radius: 6px 6px 16px 16px;
	border: 10px;
	padding: 0px 1rem;
}
.startDate__tab {
	background-color: #e9f4ed;
	color: #438d5e;
	border-radius: 6px 6px 16px 16px;
	border: 10px;
	padding: 0px 1rem;
}
.font_size {
	font-size: 10px !important;
}
.references {
	margin-left: calc(1 * -31px);
}
/* .dropdownCard {
  margin-right: 0.18rem !important;
  margin-left: 0.12rem !important;
} */
.header {
	display: inline;
	border-radius: 12px;
}

.BatchAssessmentsCard {
	height: 21rem !important;
	padding-top: 0.13rem;
}

.percentageColour {
	color: #f47951;
}

.columnColor {
	color: #f26531;
}

/* Assessment Form */
.assessmentSearchIcon {
	margin-top: 10px;
	margin-left: -30px;
	position: relative;
	display: block;
	font-size: 20px;
	color: gray;
}
.assessmentdeleteIcon {
	margin-top: 12px;
	margin-left: -28px;
	position: relative;
	display: none;
}
.assessmentdeleteIcon:hover {
	cursor: pointer;
}
.edit-icon,
.delete-icon {
	color: gray;
}
.edit-icon:hover {
	color: #4b89dc;
	cursor: pointer;
}
.delete-icon:hover {
	color: brown;
	cursor: pointer;
}
/* Trainee Assessment Submission */
.uploadedFileName {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.fileDeleteIcon {
	padding-bottom: 1rem;
}

.submitBtn {
	background-color: #f47951 !important;
	color: #ffffff !important;
	border: none !important;
}

.cancelBtn {
	background-color: #b5b5b5 !important;
	color: #ffffff !important;
	border: none !important;
}

.descriptionInput {
	height: 150px;
}
/* Trainer Result Submission */
.accordion-header {
	position: relative;
}

.accordion-arrow {
	position: absolute;
	top: 50%;
	right: 1rem; /* Adjust the spacing as needed */
	transform: translateY(-50%);
}

.titleColor {
	color: #f47951;
}

.labelWithSpace {
	margin-right: 16px;
}

/* .assessChooseFile {
	width: 6.688rem !important;
} */

/* .assessUploadFile {
	width: calc(100% - 7.65rem) !important;
	background-color: #F6F7F8 !important;
	border-radius: 0rem 0.313rem 0.313rem 0rem !important;
} */
