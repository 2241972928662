/*Trainer Profile */
.employee-profile-image {
	height: 145px;
	opacity: 1;
	border-radius: 100%;
	border: 2px solid #4b89dc;

	margin-left: 27px;
}
.employee-profile-title {
	font-size: 20px;
	font-weight: 600;
}
.employee-profile-values span {
	font-size: 16px;
	color: rgba(18, 15, 26, 0.5);
}
.size {
	width: 100%;
}
.status-description_key {
	color: #f47951;
	font-weight: 500;
}
.status-description_value {
	color: #120f1a;
}

/* Skills CSS */
/* .chip {
	font-size: 16px;
	border-radius: 25px;
	background-color: #f47951;
} */
.chip {
	font-size: 16px !important;
	border-radius: 6px !important;
	background-color: #88b1da !important;
}

/* Status CSS */
.form-check-input:checked {
	background-color: #88b1da !important;
	border-color: #88b1da !important;
}
