.navbar {
	height: 3.5rem;
}

.fission-logo {
	width: 13rem;
	height: 2rem;
}

.userIcon {
	width: 1.9rem;
}

#basic-nav-dropdown {
	color: #130f1a;
}

.dropdown-item.active,
.dropdown-item:active {
	/* color: var(--bs-dropdown-link-active-color);
    text-decoration: none; */
	background: #f5f7fd !important;
	color: #666 !important;
}
