.question-no-header {
	width: 150px; /* Adjust the width as needed */
}

.bold-label {
	font-weight: bold;
	/* font-size: 1em; */
}

.title {
	color: #f47951;
	padding: 0px !important;
}

.rowError {
	background-color: rgb(206, 185, 185);
}
